*,
::after,
::before {
  box-sizing: border-box;
}

body,
header,
main,
section,
aside,
h1,
h2,
h3,
h4,
h5,
h6,
form,
input,
button,
select,
textarea,
table,
colgroup,
col,
thead,
tbody,
tfoot,
tr,
th,
td,
ol,
ul,
p,
a,
strong,
em,
img,
div,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
svg {
  max-width: 100%;
  vertical-align: top;
}

input,
textarea,
select,
button {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
}

input[type='checkbox'],
input[type='radio'],
select,
button {
  cursor: pointer;
}
