.ZoneList__Item {
  position: relative;
}

.ZoneList__Item:not(:first-child) {
  margin-top: 1.875rem;
}

.ZoneList__Link {
  display: block;
  font-size: var(--font-regular);
}

.ZoneList__Link:hover,
.ZoneList__Link.active {
  font-weight: 700;
}

.ZoneList__Link.active::after {
  position: absolute;
  inset: 0 auto 0 -1.25rem;
  width: 0.3125rem;
  background-color: var(--blue);
  content: '';
}
