.AppointmentType {
  flex: 0 0;
  position: relative;
  display: flex;
  align-items: stretch;
  white-space: nowrap;
}

@media screen and (max-width: 1240px) {
  .AppointmentType:first-child {
    margin-left: auto;
  }
}

.AppointmentType__Input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.AppointmentType__Text {
  display: flex;
  align-items: center;
  column-gap: 0.4286em;
  padding: 0.1429em 0.7143em;
  border-radius: 1rem;
  background-color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
}

.AppointmentType__Text::before {
  flex-shrink: 0;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #fff;
  content: '';
}

.AppointmentType[data-product-type='구장직접'] .AppointmentType__Text {
  background-color: var(--product-bg-blue);
  color: var(--product-font-blue);
}

.AppointmentType[data-product-type='구장직접']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-blue);
}

.AppointmentType[data-product-type='장기대관'] .AppointmentType__Text {
  background-color: var(--product-bg-purple);
  color: var(--product-font-purple);
}

.AppointmentType[data-product-type='장기대관']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-purple);
}

.AppointmentType[data-product-type='아카데미'] .AppointmentType__Text {
  background-color: var(--product-bg-green);
  color: var(--product-font-green);
}

.AppointmentType[data-product-type='아카데미']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-green);
}

.AppointmentType[data-product-type='플랩대관'] .AppointmentType__Text {
  background-color: var(--product-bg-orange);
  color: var(--product-font-maroon);
}

.AppointmentType[data-product-type='플랩대관']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-orange);
}

.AppointmentType[data-product-type='소셜매치'] .AppointmentType__Text {
  background-color: var(--product-bg-yellow);
  color: var(--product-font-maroon);
}

.AppointmentType[data-product-type='소셜매치']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-yellow);
}

.AppointmentType[data-product-type='소셜대기/대관가능'] .AppointmentType__Text {
  border: 1px dashed var(--product-highlight-orange);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.AppointmentType[data-product-type='소셜대기/대관가능']
  .AppointmentType__Input
  + .AppointmentType__Text::before {
  border: 1px solid var(--product-highlight-orange);
}

.AppointmentType[data-product-type='소셜대기/대관가능']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-orange);
}

.AppointmentType[data-product-type='소셜대기/대관불가']
  .AppointmentType__Input
  + .AppointmentType__Text::before {
  border: 1px solid var(--product-highlight-yellow);
}

.AppointmentType[data-product-type='소셜대기/대관불가'] .AppointmentType__Text {
  border: 1px dashed var(--product-highlight-yellow);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.AppointmentType[data-product-type='소셜대기/대관불가']
  .AppointmentType__Input:checked
  + .AppointmentType__Text::before {
  background-color: var(--product-highlight-yellow);
}
