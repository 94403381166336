@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.4/dist/web/variable/pretendardvariable.css');

:root {
  --blue: #1570ff;
  --font-black: #222836;
  --font-grey: #3e5463;
  --font-lightgrey: #9fb1bd;
  --font-large: 1.125rem;
  --font-regular: 1rem;
  --font-small: 0.875rem;
  --header-height: 3.75rem;
  --page-wrap-right-left-padding: 2.5rem;
}

@media screen and (max-width: 1024px) {
  :root {
    --page-wrap-right-left-padding: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 87.5%;
  }

  :root {
    --font-large: 1.2858rem;
    --font-regular: 1.1429rem;
    --font-small: 1rem;
    --page-wrap-right-left-padding: 2.6042vw;
  }
}

@media screen and (max-width: 375px) {
  html {
    font-size: 75%;
  }

  :root {
    --font-large: 1.3334rem;
    --font-regular: 1.1667rem;
    --font-small: 1rem;

    --page-wrap-right-left-padding: 0.8334rem;
  }
}

body {
  min-width: 320px;
  font-weight: 400;
  font-size: var(--font-regular);
  line-height: 1.5;
  font-family: 'Pretendard Variable', -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo',
    'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  color: var(--font-black);
  overflow-wrap: break-word;
  word-break: keep-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-height: 100%;
}
