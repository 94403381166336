.DayScaleEmptyCell {
  flex: 1 0;
  position: relative;
  width: 100%;
  min-width: var(--scheduler-table-first-column-width);
  max-width: var(--scheduler-table-first-column-width);
  padding: var(--scheduler-dayscale-cell-padding);
}

.DayScaleEmptyCell::after {
  position: absolute;
  inset: auto 0 -1px 0;
  border-bottom: 1px solid #fff;
  content: '';
}
