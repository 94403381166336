.InputText {
  width: 100%;
}

.InputText__Input::placeholder {
  color: var(--form-input-placeholder-color);
}

.InputText.disabled {
  cursor: no-drop;
}

.InputText__Input {
  width: 100%;
  height: var(--form-input-height);
  padding: var(--form-input-padding);
  border: 1px solid var(--form-input-border-color);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-bg-color);
  color: var(--form-input-black);
  font-size: var(--form-input-font-regular);
}

.InputText.disabled .InputText__Input {
  background-color: var(--form-input-bg-disabled-color);
  color: var(--form-input-font-disabled-color);
  pointer-events: none;
}
