.InputRadio {
  display: flex;
  align-items: center;
}

.InputRadio.has-tip {
  align-items: flex-start;
}

.InputRadio__Input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--form-input-border-color);
  border-radius: 50%;
  background-color: #fff;
}

.InputRadio__Input:checked {
  border-color: var(--form-input-blue);
}

.InputRadio__Input:checked::after {
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background-color: var(--form-input-blue);
  content: '';
}

.InputRadio__Input:checked:disabled {
  border-color: var(--form-input-font-disabled-color);
}

.InputRadio__Input:checked:disabled::after {
  background-color: var(--form-input-font-disabled-color);
}

.InputRadio__Text {
  flex: 1 1;
  font-size: var(--form-input-font-regular);
  color: var(--form-input-black);
}

.InputRadio.limitedWidth45 .InputRadio__Text {
  flex: 0 0 2.8125rem;
  min-width: 2.8125rem;
  max-width: 2.8125rem;
}

.InputRadio__Tip {
  font-size: var(--form-input-font-small);
  color: var(--form-input-darkgrey);
}

@media screen and (max-width: 768px) {
  .InputRadio__Input {
    width: 1.7143rem;
    height: 1.7143rem;
  }

  .InputRadio__Input:checked::after {
    width: 1rem;
    height: 1rem;
  }

  .InputRadio.limitedWidth45 .InputRadio__Text {
    flex: 0 0 3.2143rem;
    min-width: 3.2143rem;
    max-width: 3.2143rem;
  }
}

@media screen and (max-width: 375px) {
  .InputRadio__Input {
    width: 2rem;
    height: 2rem;
  }

  .InputRadio__Input:checked::after {
    width: 1.1667rem;
    height: 1.1667rem;
  }
  .InputRadio.limitedWidth45 .InputRadio__Text {
    flex: 0 0 3.75rem;
    min-width: 3.75rem;
    max-width: 3.75rem;
  }
}

.InputRadio__Text:not(:empty) {
  margin-left: 0.25em;
}

.InputRadio__Input:disabled,
.InputRadio__Input:disabled + .InputRadio__Text {
  color: var(--form-input-font-disabled-color);
  cursor: no-drop;
}
