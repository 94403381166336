.Select {
  position: relative;
  width: 100%;
}

.Select.disabled {
  cursor: no-drop;
}

.Select__Input {
  width: 100%;
  height: var(--form-input-height);
  padding: var(--form-input-padding);
  border: 1px solid var(--form-input-border-color);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-bg-color);
  color: var(--form-input-black);
  font-size: var(--form-input-font-regular);
}

.Select.disabled .Select__Input {
  background-color: var(--form-input-bg-disabled-color);
  color: var(--form-input-font-disabled-color);
  pointer-events: none;
}

.Select__Input.has-placeholder {
  color: var(--form-input-placeholder-color);
}

.Select__Icon {
  position: absolute;
  top: 50%;
  right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  transform: translateY(-50%) rotate(-90deg);
}

.Select.disabled .Select__Icon path {
  stroke: var(--form-input-font-disabled-color);
}
