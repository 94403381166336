.DayScaleLayout {
  position: sticky;
  top: 42px;
  z-index: 90;
  display: flex;
  padding-top: 0.875rem;
  border-bottom: 1px solid var(--scheduler-table-border-color);
  background-color: #fff;
}

@media screen and (max-width: 1240px) {
  .DayScaleLayout {
    top: 97px;
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .DayScaleLayout {
    top: 85px;
  }
}

@media screen and (max-width: 375px) {
  .DayScaleLayout {
    top: 73px;
  }
}
