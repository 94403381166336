.DateNavigator {
  position: sticky;
  top: 0;
  z-index: 90;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .DateNavigator {
    height: 85px;
  }
}

@media screen and (max-width: 375px) {
  .DateNavigator {
    height: 73px;
  }
}
