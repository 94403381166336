.TimeTableRow {
  flex: 1 1;
  display: flex;
}

.TimeTableRow:last-child > div::before {
  position: absolute;
  inset: auto 0 -1px 0;
  z-index: 100;
  border-bottom: 1px solid var(--scheduler-table-border-color);
  content: '';
}
