.ToggleBtn {
  position: relative;
}

.ToggleBtn__Input {
  display: flex;
  align-items: center;
  width: 3rem;
  height: 1.5rem;
  padding: 0.125rem;
  border-radius: 2.5rem;
  background-color: var(--form-input-border-color);
  transition: background-color 0.25s;
}

.ToggleBtn__Input:checked {
  background-color: var(--form-input-blue);
}

.ToggleBtn__Input::before {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0;
  border-radius: 50%;
  background-color: #fff;
  transition: margin-left 0.25s;
  content: '';
}

.ToggleBtn__Input:checked::before {
  margin-left: calc(100% - 1.25rem);
}

@media screen and (max-width: 768px) {
  .ToggleBtn__Input {
    width: 3.4286rem;
    height: 1.7143rem;
  }

  .ToggleBtn__Input::before {
    width: 1.4286rem;
    height: 1.4286rem;
  }

  .ToggleBtn__Input:checked::before {
    margin-left: calc(100% - 1.4286rem);
  }
}

@media screen and (max-width: 375px) {
  .ToggleBtn__Input {
    width: 4rem;
    height: 2rem;
  }

  .ToggleBtn__Input::before {
    width: 1.6667rem;
    height: 1.6667rem;
  }

  .ToggleBtn__Input:checked::before {
    margin-left: calc(100% - 1.6667rem);
  }
}
