.Backdrop {
  position: fixed;
  inset: 0;
  z-index: 800;
  background-color: rgba(34, 43, 50, 0.85);
}

.Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 900;
  color: #fff;
  transform: translateZ(0) translateY(-50%);
  animation-delay: -0.16s;
}

.Loader,
.Loader:before,
.Loader:after {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  animation-fill-mode: both;
  animation: loaderFadInOut 1.8s infinite ease-in-out;
}

.Loader:before,
.Loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.Loader:before {
  left: -1.5rem;
  animation-delay: -0.32s;
}
.Loader:after {
  left: 1.5rem;
  animation-delay: 0.16s;
}

@keyframes loaderFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 1.125rem 0 -0.585rem;
  }
  40% {
    box-shadow: 0 1.125rem 0 0;
  }
}
