.TimeTableCell {
  flex: 1 1;
  position: relative;
  min-height: 10rem;
  border-right: 1px solid var(--scheduler-table-border-color);
  border-bottom: 1px solid var(--scheduler-table-border-color);
}

.TimeTableCell:first-child:after {
  position: absolute;
  inset: 0 auto 0 0;
  border-left: 1px solid var(--scheduler-table-border-color);
  content: '';
}

.TimeTableCell__Inner {
  width: 100%;
  height: 100%;
  padding: var(--scheduler-table-cell-padding);
}

.TimeTableCell.today .TimeTableCell__Inner {
  background-color: var(--scheduler-timetable-cell-bg-today-color);
}

.TimeTableCell.blur .TimeTableCell__Inner {
  opacity: 0.3;
}

.TimeTableCell__Date {
  font-size: var(--scheduler-dayscale-cell-font-regular);
  text-align: center;
}

.TimeTableCell.sunday .TimeTableCell__Date {
  color: var(--scheduler-font-red);
}

.TimeTableCell__DataList {
  padding: 0.375rem 0.25rem;
}

.TimeTableCell__ViewSwitcher {
  display: none;
}

@media screen and (max-width: 375px) {
  .TimeTableCell__ViewSwitcher {
    position: absolute;
    inset: 0;
    display: block;
  }
}
