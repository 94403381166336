.StadiumSelect {
  position: relative;
}

.StadiumSelect__Select {
  width: 100%;
  padding-right: 1.875rem;
  background-color: #fff;
  font-weight: 700;
  font-size: var(--font-large);
  white-space: normal;
}

.StadiumSelect__Icon {
  position: absolute;
  top: calc(1.25rem / 4);
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  transform: rotate(-90deg);
}

.SearchForm {
  position: relative;
  margin-bottom: 10px;
}

.SearchForm__Input {
  width: 100%;
  height: 40px;
  padding: 6px 41px 6px 12px;
  border: 1px solid var(--form-input-border-color);
  border-radius: 4px;
  background-color: var(--form-input-bg-color);
  color: var(--form-input-black);
  font-size: 16px;
  outline: none;
}

.SearchForm__SubmitBtn {
  position: absolute;
  inset: 1px 1px 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 0 3px 3px 0;
  background-color: var(--form-input-border-color);
}

.SearchForm__Input::-webkit-calendar-picker-indicator {
  display: none !important;
}
