.CheckboxCircle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CheckboxCircle__Input {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: var(--form-input-bg-color);
}

@media screen and (max-width: 768px) {
  .CheckboxCircle__Input {
    width: 2.5715rem;
    height: 2.5715rem;
  }
}

@media screen and (max-width: 375px) {
  .CheckboxCircle__Input {
    width: 3rem;
    height: 3rem;
  }
}

.CheckboxCircle__Input:checked {
  background-color: var(--form-input-blue);
}

.CheckboxCircle__Text {
  position: absolute;
  font-size: var(--form-input-font-regular);
  color: var(--form-input-grey);
}

.CheckboxCircle__Input:checked + .CheckboxCircle__Text {
  color: #fff;
}

.CheckboxCircle__Input:disabled,
.CheckboxCircle__Input:disabled + .CheckboxCircle__Text {
  cursor: no-drop;
}
