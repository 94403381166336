.DateNavigation {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.DateNavigation__PrevBtn,
.DateNavigation__NextBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
}

.DateNavigation__TodayBtn {
  min-width: 3.25rem;
  padding: 0.125em 0.25em;
  border: 1px solid #c1ccd6;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: var(--font-regular);
}

@media screen and (max-width: 1240px) {
  .DateNavigation {
    margin-left: auto;
  }
}
