.AppointmentsFilter {
  overflow-x: auto;
  display: flex;
  column-gap: 0.25rem;
  margin-left: auto;
}

@media screen and (max-width: 1240px) {
  .AppointmentsFilter {
    width: 100%;
    padding: 0.9375rem 0;
  }
}
