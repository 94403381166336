.AppointmentForm {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  background-color: #fff;
}

.InputLabel {
  flex: 0 0 3.75rem;
  font-weight: 700;
  font-size: var(--form-input-font-regular);
}

.InputLabel.textarea {
  padding: var(--form-input-textarea-padding);
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.InputLabel.hidden {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
}

.AppointmentForm__Row.toggle {
  overflow: visible;
  visibility: visible;
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  max-height: 12.5rem;
  transition: max-height 0.4s, visibility 0.4s;
}

.AppointmentForm__EndTime-Date {
  position: absolute;
  top: 0;
  left: -6px;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #3e5463;
  font-size: 12px;
  color: #fff;
  transform: translateY(-40%);
  cursor: default;
}

@media screen and (max-width: 375px) {
  .AppointmentForm__Row.toggle {
    max-height: 13.75rem;
  }
}

.AppointmentForm__Row.toggle.hidden {
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
}
