.HamburgerBtn {
  position: fixed;
  top: calc(var(--header-height) / 2);
  left: var(--page-wrap-right-left-padding);
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  transform: translateY(-50%);
  transition: top 0.25s;
}

.HamburgerBtn.hidden {
  top: -1.875rem;
}
