:root {
  /* Highlight */
  --product-highlight-blue: #1570ff;
  --product-highlight-yellow: #fed014;
  --product-highlight-orange: #ff7561;
  --product-highlight-green: #00a466;
  --product-highlight-purple: #952da7;
  --product-highlight-grey: #5b7282;
  --product-highlight-lightgrey: #c1ccd6;

  /* Background */
  --product-bg-blue: #e9faff;
  --product-bg-yellow: #fff5ba;
  --product-bg-orange: #ffe4c3;
  --product-bg-green: #f0ffd4;
  --product-bg-purple: #f3e9ff;
  --product-bg-grey: #dce3e8;
  --product-bg-white: #fff;

  /* Font */
  --product-font-blue: #2139b2;
  --product-font-maroon: #542d19;
  --product-font-green: #0a623d;
  --product-font-purple: #952da7;
  --product-font-grey: #3e5463;

  /* Block */
  --product-monthview-block-padding: 0 0.375em;
  --product-monthview-block-radius: 0.25rem;
  --product-weekview-block-padding: 0.2858em 1em;
  --product-weekview-block-radius: 0.625rem;
  --product-weekview-block-vertical-line-width: 0.5rem;
}

@media screen and (max-width: 768px) {
  :root {
    /* Block */
    --product-monthview-block-padding: 0 3px;
    --product-weekview-block-padding: 0.1667em 0.5834em;
    --product-weekview-block-radius: 0.5715rem;
    --product-weekview-block-vertical-line-width: 0.2858rem;
  }
}
