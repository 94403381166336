.Backdrop {
  position: fixed;
  inset: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
}

.ModalOverlay {
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  width: 26.25rem;
  max-height: 98vh;
  border-radius: 1.875rem;
  background-color: #fff;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 1px 1px 5px, rgba(15, 15, 15, 0.2) 4px 6px 14px;
  transform: translate(-50%, -50%);
}

.ModalOverlay.modalSmall {
  width: 23.125rem;
}

.ModalOverlay.hidden {
  visibility: hidden;
  opacity: 0;
}

.ModalOverlay__Header {
  position: sticky;
  top: 0;
  z-index: 50;
  padding: 1.875rem 0.9375rem 0;
  background-color: #fff;
}

.ModalOverlay__HeaderInner {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #dce3e8;
}

.ModalOverlay__Title {
  font-size: var(--font-large);
}

.ModalOverlay__Paragraph {
  width: 100%;
  margin-top: 0.3125rem;
  padding-right: 1.875rem;
  color: var(--font-grey);
  font-size: var(--font-small);
  line-height: 1.35;
}

.ModalOverlay__CloseBtn {
  position: absolute;
  top: 1.875rem;
  right: 0.9375rem;
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.ModalOverlay__Body {
  padding: 0.9375rem 0.9375rem 1.875rem;
}

@media screen and (max-width: 768px) {
  .ModalOverlay__CloseBtn {
    width: 1.5715rem;
    height: 1.5715rem;
  }
}

@media screen and (max-width: 375px) {
  .ModalOverlay.fullScreen {
    overflow-y: auto;
    position: fixed;
    inset: 0;
    width: 100%;
    max-height: 100%;
    border-radius: 0;
    transform: none;
  }

  .ModalOverlay__CloseBtn {
    width: 1.6667rem;
    height: 1.6667rem;
  }
}
