.DayScaleCell {
  flex: 1 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--scheduler-dayscale-cell-height);
  padding: var(--scheduler-dayscale-cell-padding);
  border-right: 1px solid transparent;
  font-size: var(--scheduler-dayscale-cell-font-regular);
  color: var(--scheduler-font-grey);
  text-align: center;
}

.DayScaleCell.sunday {
  color: var(--scheduler-font-red);
}

.DayScaleCell.today .DayScaleCell__Inner {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: var(--blue);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .DayScaleCell.today .DayScaleCell__Inner {
    border-radius: 0.2858rem;
  }
}

@media screen and (max-width: 330px) {
  .DayScaleCell {
    padding: 1px;
  }
}
