.UserSetting {
  display: flex;
  justify-content: space-between;
  padding: 0 0.625rem;
  margin-top: 0.625rem;
}

.UserSetting__Change-PWD {
  color: var(--font-lightgrey);
}
