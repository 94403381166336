:root {
  /* Input */
  --form-input-height: 3.375rem;
  --form-input-padding: 0.375em 1em;
  --form-input-textarea-padding: 0.75em 1em;
  --form-input-radius: 1rem;

  /* Input - Font */
  --form-input-font-regular: 1rem;
  --form-input-font-small: 0.75rem;
  --form-input-font-disabled-color: #9fb1bd;
  --form-input-placeholder-color: #9fb1bd;

  /* Input - Color */
  --form-input-black: #222836;
  --form-input-blue: #1570ff;
  --form-input-grey: #9fb1bd;
  --form-input-darkgrey: #5b7282;
  --form-input-white: #fff;
  --form-input-border-color: #dce3e8;
  --form-input-border-disabled-color: #9fb1bd;
  --form-input-bg-color: #fafafa;
  --form-input-bg-disabled-color: #f2f5f7;

  /* Button */

  /* Button - Color */
  --form-button-bg-disabled-color: #9fb1bd;
  --form-button-font-disabled-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--form-input-black) !important;
  box-shadow: 0 0 0 1000px var(--form-input-bg-color) inset !important;
}

@media screen and (max-width: 768px) {
  :root {
    /* Input */
    --form-input-height: 3.8572rem;
    --form-input-radius: 1.1429rem;

    /* Input - Font */
    --form-input-font-regular: 1.1429rem;
    --form-input-font-small: 0.8572rem;
  }
}

@media screen and (max-width: 375px) {
  :root {
    /* Input */
    --form-input-height: 4.5rem;
    --form-input-radius: 1.3334rem;

    /* Input - Font */
    --form-input-font-regular: 1.3334rem;
    --form-input-font-small: 1rem;
  }
}
