.Appointment {
  position: absolute;
  top: var(--scheduler-table-cell-padding);
  right: var(--scheduler-table-cell-padding);
  left: var(--scheduler-table-cell-padding);
  z-index: 10;
}

.Appointment.splittedBlock {
  top: 0 !important;
}

.Appointment__Inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--product-weekview-block-padding);
  padding-right: 2px;
  border-radius: var(--product-weekview-block-radius);
  font-weight: 600;
  font-size: var(--scheduler-timetable-cell-font-regular);
  line-height: 1.25;
  cursor: pointer;
}

.Appointment__Inner::after {
  position: absolute;
  inset: 0 auto 0 0;
  width: 100%;
  height: 100%;
  border-radius: var(--product-weekview-block-radius) 0 0
    var(--product-weekview-block-radius);
  clip-path: inset(
    0 calc(100% - var(--product-weekview-block-vertical-line-width)) 0 0
  );
  background-color: var(--font-lightgrey);
  content: '';
}

.Appointment.splittedBlock .Appointment__Inner {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: calc(100% + var(--scheduler-table-cell-padding));
}

.Appointment:is(
    [data-product-type='예약가능'],
    [data-product-type='소셜대기/대관가능'],
    [data-product-type='소셜대기/대관불가']
  ).splittedBlock
  .Appointment__Inner {
  border-top: 0;
}

.Appointment.splittedBlock .Appointment__Inner::after {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: calc(100% + var(--scheduler-table-cell-padding));
}

.Appointment__Inner::before {
  position: absolute;
  inset: var(--scheduler-table-cell-prevent-event-padding);
  content: '';
}

.Appointment.hidden .Appointment__Inner {
  opacity: 0;
  cursor: default;
}

.Appointment__Consumer {
  width: 100%;
}

.Appointment .Appointment__Consumer {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.Appointment.oneHour .Appointment__Consumer {
  display: block;
  -webkit-line-clamp: initial;
  -webkit-box-orient: initial;
  white-space: nowrap;
}

.Appointment.isOriginalOfSplittedBlock .Appointment__Consumer,
.Appointment.halfHour.splittedBlock .Appointment__Consumer {
  -webkit-line-clamp: 1;
}

.Appointment.halfHour.splittedBlock .Appointment__Price,
.Appointment.hidePrice:not([data-product-type='예약가능']) .Appointment__Price,
.Appointment:is(.oneHour, .oneHourHalf):not(
    .splittedBlock,
    [data-product-type='예약가능']
  )
  .Appointment__Price {
  display: none;
}

.Appointment.splittedBlock.hideInfo .Appointment__Consumer,
.Appointment.splittedBlock.hideInfo .Appointment__Price {
  display: none;
}

@media screen and (max-width: 830px) {
  .Appointment.oneHour[data-product-type='예약가능']:not(.splittedBlock)
    .Appointment__Price,
  .Appointment.hidePrice[data-product-type='예약가능'] .Appointment__Price {
    display: none;
  }

  .Appointment__Time--Tilde {
    display: block;
    line-height: 0.6;
  }
}

@media screen and (min-width: 441px) and (max-width: 830px) {
  .Appointment.isOriginalOfSplittedBlock .Appointment__Consumer {
    -webkit-line-clamp: 1;
  }
}

@media screen and (max-width: 768px) {
  .Appointment.oneHour:not(.splittedBlock) .Appointment__Consumer {
    display: none;
  }
}

.Appointment[data-product-type='구장직접'] .Appointment__Inner {
  background-color: var(--product-bg-blue);
  color: var(--product-font-blue);
}

.Appointment[data-product-type='구장직접'] .Appointment__Inner::after {
  background-color: var(--product-highlight-blue);
}

.Appointment[data-product-type='장기대관'] .Appointment__Inner {
  background-color: var(--product-bg-purple);
  color: var(--product-font-purple);
}

.Appointment[data-product-type='장기대관'] .Appointment__Inner::after {
  background-color: var(--product-highlight-purple);
}

.Appointment[data-product-type='아카데미'] .Appointment__Inner {
  background-color: var(--product-bg-green);
  color: var(--product-font-green);
}

.Appointment[data-product-type='아카데미'] .Appointment__Inner::after {
  background-color: var(--product-highlight-green);
}

.Appointment[data-product-type='플랩대관'] .Appointment__Inner {
  background-color: var(--product-bg-orange);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='플랩대관'] .Appointment__Inner::after {
  background-color: var(--product-highlight-orange);
}

.Appointment[data-product-type='소셜매치'] .Appointment__Inner {
  background-color: var(--product-bg-yellow);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜매치'] .Appointment__Inner::after {
  background-color: var(--product-highlight-yellow);
}

.Appointment[data-product-type='소셜대기/대관가능'] .Appointment__Inner {
  border: 1px dashed var(--product-highlight-orange);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜대기/대관가능'] .Appointment__Inner::after {
  background-color: var(--product-highlight-orange);
}

.Appointment[data-product-type='소셜대기/대관불가'] .Appointment__Inner {
  border: 1px dashed var(--product-highlight-yellow);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜대기/대관불가'] .Appointment__Inner::after {
  background-color: var(--product-highlight-yellow);
}

.Appointment[data-product-type='예약불가'] .Appointment__Inner {
  background-color: var(--product-bg-grey);
  color: var(--product-font-grey);
}

.Appointment[data-product-type='예약불가'] .Appointment__Inner::after {
  background-color: var(--product-highlight-grey);
}

.Appointment[data-product-type='예약가능'] .Appointment__Inner {
  border: 1px dashed var(--product-highlight-lightgrey);
  background-color: var(--product-bg-white);
  color: var(--product-font-grey);
}

.Appointment[data-product-type='예약가능'] .Appointment__Inner::after {
  background-color: var(--product-highlight-lightgrey);
}
