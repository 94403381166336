.InputPrice {
  position: relative;
  width: 100%;
}

.InputPrice.disabled {
  cursor: no-drop;
}

.InputPrice__Input {
  position: absolute;
  inset: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: var(--form-input-padding);
  border: 1px solid transparent;
  border-radius: var(--from-input-radius);
  background-color: transparent;
  font-size: var(--form-input-font-regular);
  color: var(--form-input-black);
}

.InputPrice__Text {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--form-input-height);
  padding: var(--form-input-padding);
  border: 1px solid var(--form-input-border-color);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-bg-color);
  color: transparent;
  font-size: var(--form-input-font-regular);
}

.InputPrice__Text::after {
  font-size: var(--form-input-font-regular);
  color: var(--form-input-black);
  content: '원';
}

.InputPrice.disabled .InputPrice__Input {
  color: var(--form-input-font-disabled-color);
  pointer-events: none;
}

.InputPrice.disabled .InputPrice__Text {
  background-color: var(--form-input-bg-disabled-color);
}

.InputPrice.disabled .InputPrice__Text::after {
  color: var(--form-input-font-disabled-color);
}

.InputPrice__Input:focus {
  outline: none;
}

.InputPrice__Input:focus + .InputPrice__Text {
  outline: 2px solid #005fcc;
}
