.Checkbox {
  display: flex;
  align-items: center;
}

.Checkbox__Input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--form-input-border-color);
  border-radius: 4px;
  background-color: var(--form-input-white);
}

.Checkbox__Input:checked {
  border-color: var(--form-input-blue);
  background-color: var(--form-input-blue);
}

.Checkbox__Input:checked::after {
  width: 0.75rem;
  height: 0.4375rem;
  margin-top: -0.25rem;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  content: '';
}

.Checkbox__Input:checked:disabled {
  border-color: var(--form-input-font-disabled-color);
  background-color: var(--form-input-font-disabled-color);
}

.Checkbox__Input:disabled {
  background-color: var(--form-input-bg-disabled-color);
}

.Checkbox__Text {
  flex: 1 1;
  font-size: var(--form-input-font-regular);
  color: var(--form-input-black);
}

@media screen and (max-width: 768px) {
  .Checkbox__Input {
    width: 1.4286rem;
    height: 1.4286rem;
  }

  .Checkbox__Input:checked::after {
    width: 0.8572rem;
    height: 0.5rem;
  }
}

@media screen and (max-width: 375px) {
  .Checkbox__Input {
    width: 1.6667rem;
    height: 1.6667rem;
  }

  .Checkbox__Input:checked::after {
    width: 1rem;
    height: 0.5834rem;
  }
}

.Checkbox__Text:not(:empty) {
  margin-left: 0.25em;
}

.Checkbox__Input:disabled,
.Checkbox__Input:disabled + .Checkbox__Text {
  border-color: var(--form-input-font-disabled-color);
  color: var(--form-input-font-disabled-color);
  cursor: no-drop;
}
