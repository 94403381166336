.Header {
  position: fixed;
  inset: 0 0 auto;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding: 0 var(--page-wrap-right-left-padding);
  background-color: #fff;
  box-shadow: 0 10px 10px rgba(15, 15, 15, 0.04);
  transition: transform 0.25s, box-shadow 0.25s;
}

.Header.hidden {
  box-shadow: 0 0 0 rgb(255 255 255);
  transform: translateY(-100%);
}
