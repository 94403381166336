.FormControlWrap {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
  background-color: #fff;
}

.FormControlWrap.space-between {
  justify-content: space-between;
}

.FormControlWrap.flex-end {
  justify-content: flex-end;
}

.FormControlWrap.align--flex-start {
  align-items: flex-start;
}
