.DateDisplay {
  width: 10.625rem;
}

@media screen and (max-width: 768px) {
  .DateDisplay {
    margin-left: var(--page-wrap-right-left-padding);
  }
}

.DateDisplay__Date {
  position: relative;
  font-size: 1.75rem;
  cursor: pointer;
}

.DateDisplay__InputDate {
  position: absolute;
  inset: 0;
  opacity: 0;
  width: 100%;
}

.DateDisplay__InputDate::-webkit-calendar-picker-indicator {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
