.GlobalNav {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.GlobalNav__Header {
  padding: 1.25rem 1.25rem 0;
}

.GlobalNav__HeaderInner {
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #dce3e8;
}

.GlobalNav__Body {
  overflow-y: auto;
  padding: 0 1.25rem 0;
}

.GlobalNav__BodyInner {
  padding: 1.25rem 0 0.625rem;
}

.GlobalNav__Footer {
  margin-top: auto;
  padding: 0 1.25rem calc(var(--header-height) + 1.25rem);
}

.LinkGuide {
  display: block;
  margin-top: 0.2rem;
  font-size: var(--font-small);
  color: var(--font-grey);
}

.LinkGuide:hover {
  font-weight: 600;
}

.GlobalNav__FooterInner {
  margin-top: 0.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid #dce3e8;
}
