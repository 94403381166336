.Button {
  width: 100%;
  height: var(--form-input-height);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-blue);
  color: var(--form-input-white);
  font-size: var(--form-input-font-regular);
}

.Button--Delete {
  background-color: var(--form-input-bg-disabled-color);
  color: var(--form-input-black);
}

.Button--Disabled {
  background-color: var(--form-button-bg-disabled-color);
  color: var(--form-button-font-disabled-color);
  cursor: no-drop;
}
