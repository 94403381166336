.ViewSwitcher {
  margin-left: 0.75rem;
}

.ViewSwitcher__Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 768px) {
  .ViewSwitcher {
    margin-right: var(--page-wrap-right-left-padding);
  }

  .ViewSwitcher__Btn {
    width: 2.1429rem;
    height: 2.1429rem;
  }
}
