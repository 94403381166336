.Aside_Backdrop {
  visibility: hidden;
  position: fixed;
  inset: var(--header-height) 0 0 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 15, 15, 0.25);
  opacity: 0;
  transition: visibility 0.25s, opacity 0.25s;
  content: '';
}

.Aside__Inner {
  position: fixed;
  z-index: 100;
  inset: var(--header-height) auto 0 0;
  width: 18.75rem;
  height: 100%;
  min-height: 23.75rem;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.25s;
}

.Aside.show .Aside_Backdrop {
  visibility: visible;
  opacity: 1;
}

.Aside.show .Aside__Inner {
  transform: translateX(0);
}
