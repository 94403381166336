.Textarea {
  width: 100%;
}

.Textarea.disabled {
  cursor: no-drop;
}

.Textarea__Input {
  width: 100%;
  height: calc(var(--form-input-height) * 2);
  padding: var(--form-input-textarea-padding);
  border: 1px solid var(--form-input-border-color);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-bg-color);
  color: var(--form-input-font-blck);
  font-size: var(--form-input-font-regular);
}

.Textarea.disabled .Textarea__Input {
  background-color: var(--form-input-bg-disabled-color);
  color: var(--form-input-font-disabled-color);
  pointer-events: none;
}

.Textarea__Input::placeholder {
  color: var(--form-input-placeholder-color);
}
