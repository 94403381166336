.NotFound {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 26.25rem;
  transform: translate(-50%, -50%);
}

.NotFound__Paragraph {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
}
