:root {
  /* Padding */
  --scheduler-table-cell-padding: 1px;
  --scheduler-dayscale-cell-padding: 4px;
  --scheduler-table-cell-prevent-event-padding: -2px;

  /* Border */
  --scheduler-table-border-color: #dce3e8;

  /* Background */
  --scheduler-timetable-cell-bg-today-color: #f2f5f7;

  /* Font */
  --scheduler-font-grey: #5b7282;
  --scheduler-font-red: #ed251b;
  --scheduler-dayscale-cell-font-regular: 1rem;
  --scheduler-timescale-label-font-regular: 1rem;
  --scheduler-timetable-cell-font-regular: 0.875rem;
  --scheduler-timetable-cell-font-small: 0.875rem;

  /* Cell Height */
  --scheduler-dayscale-cell-height: 2.5rem;
  --scheduler-weekview-timetable-cell-height: 54px;

  --scheduler-table-first-column-width: 3.125rem;
  --scheduler-table-first-column-padding-left: 0.3125rem;
}

@media screen and (max-width: 1024px) {
  :root {
    /* Font */
    --scheduler-dayscale-cell-font-regular: 0.875rem;
    --scheduler-timescale-label-font-regular: 0.875rem;
    --scheduler-timetable-cell-font-regular: 0.75rem;

    /* Cell Height */
    --scheduler-dayscale-cell-height: 1.875rem;

    --scheduler-table-first-column-width: 2.5rem;
    --scheduler-table-first-column-padding-left: 0.15625rem;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --scheduler-wrap-right-left-padding: -2.6042vw;
    --scheduler-table-first-column-width: 2.5rem;

    /* Font */
    --scheduler-dayscale-cell-font-regular: 0.8572rem;
    --scheduler-timescale-label-font-regular: 0.8572rem;
    --scheduler-timetable-cell-font-regular: 0.8572rem;
  }
}

@media screen and (max-width: 440px) {
  :root {
    --scheduler-timetable-cell-font-regular: 10px;
  }
}

@media screen and (max-width: 375px) {
  :root {
    --scheduler-wrap-right-left-padding: -0.8334rem;

    /* Font */
    --scheduler-dayscale-cell-font-regular: 1rem;
    --scheduler-timescale-label-font-regular: 1rem;
    --scheduler-timetable-cell-font-regular: 10px;
  }
}
