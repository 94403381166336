.UtilityMenu {
  font-size: var(--font-small);
}

.UtilityMenu__Title {
  color: var(--font-grey);
}

.UtilityMenu__Actions {
  margin-top: 1.25rem;
  font-weight: 700;
  color: var(--font-lightgrey);
  text-align: center;
}
