.LogIn {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 21.875rem;
  transform: translate(-50%, -50%);
}

.LogIn__Logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.75rem;
  height: 3.875rem;
  margin: 0 auto;
}

.LogInForm {
  margin-top: 2.5rem;
}

.LogInForm__Row:not(:first-child) {
  margin-top: 0.9375rem;
}
