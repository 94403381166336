.TimeTableCell {
  flex: 1 1;
  position: relative;
  border-right: 1px solid var(--scheduler-table-border-color);
  border-bottom: 1px solid var(--scheduler-table-border-color);
}

.TimeTableCell:first-child:after {
  position: absolute;
  inset: 0 auto 0 0;
  border-left: 1px solid var(--scheduler-table-border-color);
  content: '';
}

.TimeTableCell__Inner {
  position: relative;
  width: 100%;
  height: var(--scheduler-weekview-timetable-cell-height);
  padding: var(--scheduler-table-cell-padding);
}

.TimeTableCell.today .TimeTableCell__Inner {
  background-color: var(--scheduler-timetable-cell-bg-today-color);
}

.TimeTableCell.blur .TimeTableCell__Inner {
  opacity: 0.3;
}
